/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OBSERVER } from './../main.js'
//-----------------------------------------------------------------------------------------------------------------


// Permet de fermer les alertes desktop et mobile
export function alerts() {

    document.querySelector('.js-close-alerts')

    $( '.js-close-alerts' ).click(function() {
      $('.js-alertsBanner').slideUp()
    })

    /*
    OBSERVER.add('alerts', 'click', onClick, '.js-close-alerts')
    OBSERVER.on('alerts')

    window.closeAlert = new CustomEvent('closeAlert', { detail: document.querySelector('.js-close-alerts') })

    function onClick() {
      alert('bob')
      dispatchEvent(window.closeAlert)
      document.querySelector('html').classList.remove('showAlerts')
    }
    */
  }

// Open/Close List
export function ocList() {
  var title = 'Ouvrir'
  $('.plus').attr('title', title)
  console.log('Fermée')
  if($('html')[0].lang == 'en') {
    title = 'Open'
    $('.plus').attr('title', title)
    console.log('Close')
  }
  $('.ocList > li > a').unbind( 'click' ) // Pour éviter de binder le click 2 fois
  $('.ocList > li > a').on('click', function() {

    if($('html')[0].lang == 'fr') {
      title = 'Ouvrir'
      $('.plus').attr('title', title)
      console.log('Fermée')
    } else if($('html')[0].lang == 'en') {
      title = 'Open'
      $('.plus').attr('title', title)
      console.log('Fermée')
    }

    var ouvert = $(this).parent().hasClass('open')
    $('.ocList li.open .ocListCt').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.ocList li.open').removeClass('open')
    if (!ouvert) {
      if($('html')[0].lang == 'fr') {
        title = 'Refermer'
        $(this).find('.plus').attr('title', title)
        console.log('Ouverte')
      } else if($('html')[0].lang == 'en') {
        title = 'Close'
        $(this).find('.plus').attr('title', title)
        console.log('Open')
      }
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.ocListCt', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})

      console.log(chosen)
      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $(chosen).offset().top - 60
        }, 300);
      }, 400)
    }
    return false
  })
}

// Open list grâce au "url-site/#..."
export function manageRubriqueHash() {
  //Pour ouvrir une rubrique dans la même page
  window.onhashchange = function () {
    var id = location.hash.split('#').pop()
    var hash = '#rubrique' + id
    if ($('li' + hash).length) {
      hashDrawer(hash)
      $('html, body').animate({ scrollTop: (hash != '' ? $(hash).offset().top-10 : 0) }, 600, 'easeInOutExpo')
    }
  }

  //Pour ouvrir une rubrique dans une autre page
  $('document').ready(function () {
    if (location.hash.length) {
      var id = location.hash.split('#').pop()
      var hash = '#rubrique' + id
      if ($('li' + hash).length) {
        hashDrawer(hash)
        $('html, body').animate({ scrollTop: (hash != '' ? $(hash).offset().top-10 : 0) }, 600, 'easeInOutExpo')
      }
    }
  })
}

// Permet de défiler à une ancre
function hashScrolling(destination) {console.log(document.querySelector('.sRubric > div'))
  let offset = document.querySelector('.sRubric > div').dataset.scrollfire != 'fire' ? 100 : 0
  console.log(document.querySelector('.sRubric > div').dataset)
  if (SCROLLER.getPageOffset() == 0)
    $('html, body').animate({ scrollTop: (destination != '' ? $(destination).offset().top - offset : 0) }, 600, 'easeInOutExpo')
}

// Permet de gérer un tiroir avec un défilement
function hashDrawer(element) {
  $('.ocList li.open .ocListCt').animate({ height: 'hide' }, 400)
  $('.ocList li.open').removeClass('open')
  if (!$(element).hasClass('open')) {
    setTimeout(function () { $(element).addClass('open') }, 0)
    $('.ocListCt', $(element)).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }}, function () { hashScrolling(element) })
    //Enleve l'ancre de l'url une fois que la rubrique est ouverte
    history.replaceState(null, null, ' ')
  }
  return false
}

// Appliquer un filtre sur un résultat
export function faqFilters() {
  let alphabet = genCharArray('A', 'Z')

  $('#letterIndex a').on('click', function() {
    if(!($(this).hasClass('seeAll'))){
      let letter = this.id.replace('letter_', '')
      $('#letterIndex a').removeClass('active')
      $(this).addClass('active')
      for (let i = 0; i < alphabet.length; i++) {
        if(letter != alphabet[i]) {
          $('#wrapper_' + alphabet[i]).animate({ height: 'hide' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' }})
          $('#wrapper_' + alphabet[i]).removeClass('active')
        } else {
          $('#wrapper_' + alphabet[i]).animate({ height: 'show' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' }})
          $('#wrapper_' + alphabet[i]).addClass('active')
        }
      }
    }
  })

  $('.seeAll').on('click', function() {
    $('#letterIndex a').removeClass('active')
    $('.seeAll').addClass('active')
    for (let i = 0; i < alphabet.length; i++) {
      $('#wrapper_' + alphabet[i]).animate({ height: 'show' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' }})
      $('#wrapper_' + alphabet[i]).addClass('active')
    }
  })

  function genCharArray(charA, charZ) {
    let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0)
    for (; i <= j; ++i) {
      a.push(String.fromCharCode(i))
    }
    return a
  }
}

export function initFancyBoxAlert() {
  // Afficher l'alerte dès le load de page initial
  $(document).ready(function() {
    $.fancybox.open($('#alert-popup'));
  });

  // Faire disparaitre le popup
  $('#btn-closeTop, #btn-closeBottom').click( function () {
    $.fancybox.close($('#alert-popup'));
  })
}
