/*
|--------------------------------------------------------------------------
| Importation des scripts
|--------------------------------------------------------------------------
|
| Main.js importe tous les fichiers qu'on a de besoin, ça commence par les
| node-modules, puis les fichiers propres au projet.
*/

// Initialisation sur toutes les pages
import $ from 'jquery'

window.jQuery = $
window.$ = $
import 'jquery.easing'

import 'bootstrap-datepicker'
import 'eonasdan-bootstrap-datetimepicker'

import {initBarba} from './barba'

import {newsLoaded} from './global.js'
import '@fancyapps/fancybox'

import Observer from './../libraries/observer.min.js'
import Scrollfire from './../libraries/scrollfire.min.js'
import Overlay from './../libraries/overlay.js'

import FormBuilder from './classes/formbuilder/FormBuilder'

// import formBuilder from "./classes/FormBuilder";

window.newsLoaded = newsLoaded
window.FormBuilder = FormBuilder

import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.scss'
import 'slick-carousel/slick/slick.scss'

// Importation du style
import '../scss/style.scss'

// Assignation de constantes
export const OBSERVER = Observer.getInstance()
export const SCROLLFIRE = Scrollfire.getInstance()
export const OVERLAY = Overlay

// Démarrage des scripts en passant par barba et Fancybox
window.addEventListener('DOMContentLoaded', () => {
  initBarba()

  $('body').keydown(function (e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey && keycode == 13) || (e.metaKey && keycode == 13)) {
      location = '/administration'
      return false
    }
  })

})
