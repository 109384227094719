import {OBSERVER} from '../../main'
import barba from '@barba/core'

import 'jquery-mask-plugin'
import 'bootstrap-datepicker'
import 'eonasdan-bootstrap-datetimepicker'
import MultiFileUploader from './MultiFileUploader'

// Importation du style
import './formbuilder.scss'

export default class FormBuilder {


  // Initialiser le gestionnaire d’upload de fichiers et les fields speciaux
  static init() {
    MultiFileUploader.init()
    this.initCalendarInput()
    this.initTimepicker()
    this.initMasks()
    // Ecoute les erreurs du serveur pour les afficher
    $(window).on('ajaxInvalidField', this.updateErrors )
  }

  // Reset de validation
  static formSuccess(form, data) {

    if (data.X_OCTOBER_REDIRECT !== undefined) {
    // le formulaire étant remplacé par un partial cette fonction ne fait visuellement rien
    // elle reste là au cas où il faut reset le form
      this.reset()
    }

  }

  // Reset les formulaires
  static reset() {
    $(form)[0].reset()
    MultiFileUploader.reset(form)
    if (typeof grecaptcha !== 'undefined' && $('.g-recaptcha').length > 0) {
      grecaptcha.reset()
    }
  }

  // Affiche les messages d’erreurs et scroll jusqu’au premier
  static updateErrors(event, fieldElement, fieldName, errorMsg, isFirst) {
    var $field = $(fieldElement).closest('.js-form-field')

    if (!$field.length) return

    event.preventDefault()

    var $help = $('<p />').addClass('errorMessage')

    if (errorMsg) {
      $help.text(errorMsg.join(', '))
    }

    $help.addClass('form-field-error-label')
    $field.addClass('invalid')

    // Prevent the next error alert only once
    $(window).one('ajaxErrorMessage', function (event) {
      event.preventDefault()
    })

    if ($('.form-field-error-label', $field).length > 0)
      return

    var $errorPlace = $('[data-validate-field="' + fieldName + '"]', $field)
    $errorPlace.first().append($help)

    // Scroll to the form group
    if (isFirst) {
      $('html, body').animate({scrollTop: $field.offset().top - 100}, 500, function () {
        fieldElement.focus()
      })
    }
  }


  // ****************************
  // CALENDRIER ET DATES
  // ****************************

  // calendrier
  static initCalendarInput() {

    $.fn.datepicker.dates.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $('.datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $('.datepickerWrapper').data('startDate')
    })

    $('.datepickerWrapper').on('changeDate', function () {
      $(this).prev().val($(this).datepicker('getFormattedDate'))
      $('.datepickerWrapper').removeClass('show')
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'focus',
      target: '.field--input.input-calendar input',
      function: function() { $(this).parent().find('.datepickerWrapper').addClass('show') }
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: '.field--input.input-calendar',
      function: preventClose
    })

    OBSERVER.on('input-calendar')

    function closeCalendar() {
      let x, i
      x = document.querySelectorAll('.datepickerWrapper')

      for (i = 0; i < x.length; i++) {
        x[i].classList.remove('show')
      }
    }

    function preventClose(e) {
      e.stopPropagation()
    }

  }

  // Ajoute un time picker
  static initTimepicker() {
    $('.form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down'
      },
    })
  }

  // ****************************
  // MASKS
  // ****************************

  // Retourne la dropZone en fonction de son nom
  static initMasks() {
    this.formatPhone('.mask-phone')
    this.formatPostalCode('.mask-postalcode')
  }

  static formatPhone(element) {
    $(element).mask('000 000-0000')
  }

  static formatPostalCode(element) {
    $(element).mask('Z0Z 0Z0', {
      translation: {
        'Z': {
          pattern: /[A-Z, a-z]/
        }
      }
    })
  }

}

barba.hooks.beforeEnter(({current}) => {
  FormBuilder.init()
})
