/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { SCROLLFIRE, OVERLAY } from './main.js'
import {slickAlerts} from './functions/sliders'
import {alerts, faqFilters, initFancyBoxAlert, manageRubriqueHash, ocList} from './functions/functions'
import {overlayFastLinks} from './functions/overlays'
import {slickBanners} from './functions/slicks'
import {destroySlick, slickFastLinks} from './functions/sliders'

export const initBarba = () => {

  // Dans chaque beforeEnter hook
  barba.hooks.beforeEnter(({current}) => {
    //forceBlankOnExterneAndPdfLinks()
    slickAlerts()
    alerts()
    initFancyBoxAlert()
    overlayFastLinks()
    slickFastLinks()
  })

  // Dans chaque afterLeave hook
  barba.hooks.afterLeave(() => {
    destroySlick()
    OVERLAY.destroyAll()
    SCROLLFIRE.destroy()
  })

  barba.hooks.before(() => {
    barba.wrapper.classList.add('show-transition')
  })

  barba.hooks.after(() => {
    barba.wrapper.classList.remove('show-transition')
  })

  barba.init({
    cacheIgnore: false,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [{
      name: 'default-transition',
      leave: () => Promise.all([
        // leave transition
      ]),
      after: () => Promise.all([
        // enter transition
      ]),
    }],

    views: [
      { // Initialisation des scripts de la page accueil
        namespace: 'homeView',
        beforeEnter() {
          slickBanners()
        },
        afterLeave() {
        }
      },
      { // Initialisation des scripts de la page lexique
        namespace: 'lexiqueView',
        beforeEnter() {
          ocList()
          manageRubriqueHash()
          faqFilters()
        },
        afterLeave() {
        }
      },
      { // Initialisation des scripts de la page contact
        namespace: 'contactView',
        beforeEnter() {
          ocList()
          manageRubriqueHash()
        },
        afterLeave() {
        }
      },
      { // Initialisation des scripts de la page generic
        namespace: 'genericView',
        beforeEnter() {
        },
        afterLeave() {
        }
      },
      { // Initialisation des scripts de la page galerie
        namespace: 'galerieView',
        beforeEnter() {
        },
        afterLeave() {
        }
      },
      { // Initialisation des scripts de la page galerie
        namespace: 'galerieView',
        beforeEnter() {
        },
        afterLeave() {
        }
      }
    ]
  })
}
