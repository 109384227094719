/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

// Importation ----------------------------------------------------------------------------------------------------
import Overlay from '../../libraries/overlay.js'
//-----------------------------------------------------------------------------------------------------------------

// ANCHOR overlayFastLinks
// NOTE Fonction gérant l'overlay des liens rapides
export function overlayFastLinks() {
  let fastlinks = new Overlay({
    name:    'fastlinks',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-fastlinks', switch: '.js-close-all-overlay, .js-toggle-overlay-menu, .js-trigger-overlay-dropdown, .js-toggle-overlay-search', close: '.js-close-overlay-fastlinks' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      root: document,
    }
  })

  fastlinks.init()
}
