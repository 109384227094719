//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Cacher ou montrer bouton "plus de medias"
export function newsLoaded(data) {
  if (!data['hasMorePages'])
    $('#moreMediasBtn').hide()
  else {
    $('#moreMediasBtn').show()
  }
}
