//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

export function slickBanners() {
  $('#slickBanner').slick({
    arrows: false,
    dots: true,
    appendDots: '#appendDots',
    fade: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    draggable: false,
    autoplaySpeed: 6000,
    speed: 1000,
  })
}



