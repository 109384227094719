import Dropzone from 'dropzone'

export default class MultiFileUploader {

  // Simulation proprieté static
  static get DEFAULTS() {
    return {
      handler: 'onUpdateFile',
      maxSize: null,
      fileTypes: null
    }
  }

  constructor(element, options) {

    this.options = options

    this.$container = $(element)
    this.$el = $('.content', element)
    this.$clickable = $('.clickable', element)
    this.$template = $('.template', element)
    this.$fileHolder = $('div.img', this.$el)

    var acceptedFiles = this.options.fileTypes
    if (acceptedFiles == '*')
      acceptedFiles = null

    this.dropzone = new Dropzone(this.$container.get(0), {
      url: window.location,
      clickable: this.$clickable.get(0),
      previewsContainer: this.$el.get(0),
      previewTemplate: this.$template.html(),
      acceptedFiles: acceptedFiles,
      maxFilesize: this.options.maxSize,
      paramName: 'file_data'
    })

    var self = this

    this.dropzone.on('error', function (file, error) {
      alert(error)
    })

    this.dropzone.on('sending', function (file, xhr, formData) {
      self.addExtraFormData(formData)
    })

    this.dropzone.on('success', function (file, response) {
      self.updateFile(file, response)
    })

    this.$el.on('click', '.delete', function () {
      self.removeFile($(this))
    })

  }

  addExtraFormData(formData) {
    if (this.options.extraData) {
      $.each(this.options.extraData, function (name, value) {
        formData.append(name, value)
      })
    }

    var $form = this.$el.closest('form')
    if ($form.length > 0) {
      $.each($form.serializeArray(), function (index, field) {
        formData.append(field.name, field.value)
      })
    }
  }

  updateFile(file, response) {
    var $preview = $(file.previewElement),
      $img = $('.thumbnail', $preview)

    if (response.id) {
      $preview.data('id', response.id)
      $img.attr('src', response.thumb)
    }
  }

  removeFile($link) {

    var self = this,
      $preview = $link.closest('.dz-preview'),
      id = $preview.data('id')

    if (!id) {
      $preview.remove()
      return
    }

    $preview.removeClass('dz-success').addClass('dz-removing')

    var request = this.$container.closest('form').request(this.options.handler, {
      data: {
        id: id,
        mode: 'delete'
      },
      redirect: false
    })

    request.then(function () {
      $preview.remove()
    })
  }

  static init() {
    var old = $.fn.multiFileUploader

    $.fn.multiFileUploader = function (option) {
      return this.each(function () {
        var $this = $(this)
        var data = $this.data('oc.multiFileUploader')
        var options = $.extend({}, MultiFileUploader.DEFAULTS, $this.data(), typeof option == 'object' && option)

        if (!data) $this.data('oc.multiFileUploader', (data = new MultiFileUploader(this, options)))
        if (typeof option == 'string') data[option].call($this)
      })
    }

    $.fn.multiFileUploader.Constructor = MultiFileUploader

    $.fn.multiFileUploader.noConflict = function () {
      $.fn.multiFileUploader = old
      return this
    }

    // ICON UPLOAD CONTROL DATA-API
    // ===============
    this.checkDropZone()
  }

  // Appliquer les dropzones sur les elements
  static checkDropZone() {
    $('[data-control=multi-file-uploader]').multiFileUploader()
  }

  // RESET LES DROPZONE DU FORMULAIRE
  static reset(formObj) {
    // recherche des dropzones du form
    let dropZoneList = $('.fileField', $(formObj))

    // reset des dropzones du form
    dropZoneList.each((index, element) => {
      // pour chaque drop zone trouvé dans le form
      // on cherche son instance de Dropzone
      let dropZoneInstance = this.getDropzone(element.id)
      if (dropZoneInstance) {
        dropZoneInstance.removeAllFiles()
      }
    })

  }

  // Retourne la dropZone en fonction de son ID
  static getById(name) {
    let selectedDropzone = false
    Dropzone.instances.forEach((dropzone) => {
      if (dropzone.element.id == name) {
        selectedDropzone = dropzone
      }
    })
    return selectedDropzone
  }

}
