/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/

// Création du slick des alertes
export function slickAlerts() {
  if (document.querySelectorAll('.alertsBanner__list').length != 0) {
    $('.alertsBanner__list').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    let total = $('.alertsBanner__list .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $('.alertsBanner__nav .js-total').html(displayedTotal)

    $('.alertsBanner__list').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      let slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $('.alertsBanner__nav .js-current').html(slidePosition)
    })
  }
  slickSlideCount('.js-alertsBanner', '.l_alerts .slickNav .current', '.l_alerts .slickNav .total')
}


// Liens rapides (autres pages)
export const slickFastLinks = (root = document) => {
  $(root).find('.js-overlay-fastlinks-slider').slick({
    arrows: false,
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  })

  $(root).find('.js-overlay-fastlinks-slider-prev').on('click', () => {
    $(root).find('.js-overlay-fastlinks-slider').slick('slickPrev')
  })

  $(root).find('.js-overlay-fastlinks-slider-next').on('click', () => {
    $(root).find('.js-overlay-fastlinks-slider').slick('slickNext')
  })
}


// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $(totalElement).html(displayedTotal)
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(root = document) {
  $(root).find('.slick-initialized').slick('unslick')
}
